<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="pt-2 px-2">
          รายงานสรุปฝาก-ถอน Korean
        </div>
      </b-card-title>
      <b-card-body>
        <div class="row no-gutters">
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (จาก)
              </div>
            </div>

            <flat-pickr v-model="dateStart" class="form-control" placeholder="เลือกวันที่" />
          </div>
          <div class="d-flex">
            <div class="d-flex align-start">
              <div class="btn-disable">
                วันที่ (ถึง)
              </div>
            </div>

            <flat-pickr v-model="dateEnd" class="form-control" placeholder="เลือกวันที่" />
          </div>
          <button class="btn btn-primary ml-1" @click="getData()">
            ค้นหา
          </button>
        </div>

        <div class="row mt-2">
          <div class="col-12 col-md-4">
            <b-alert show variant="warning" class="text-center">
              <div>
                <h3 class="alert-heading">
                  สรุปยอดผู้สมัคร
                  <div>({{ dateStart }} ถึง {{ dateEnd }})</div>
                </h3>
              </div>
              <div class="py-2 h2 text-warning">
                {{ totalData ? totalData.ref : 0 }}
              </div>
            </b-alert>
          </div>
          <div class="col-12 col-md-4">
            <b-alert show variant="warning" class="text-center">
              <div>
                <h3 class="alert-heading">
                  สรุปจำนวนผู้ฝากเงิน
                  <div>({{ dateStart }} ถึง {{ dateEnd }})</div>
                </h3>
              </div>
              <div class="py-2 h2 text-warning">
                {{ totalData ? totalData.dep : 0 }}
              </div>
            </b-alert>
          </div>
          <div class="col-12 col-md-4">
            <b-alert show variant="warning" class="text-center">
              <div>
                <h3 class="alert-heading">
                  สรุปจำนวนผู้ไม่ได้ฝากเงิน
                  <div>({{ dateStart }} ถึง {{ dateEnd }})</div>
                </h3>
              </div>
              <div class="py-2 h2 text-warning">
                {{ totalData ? totalData.not_dep : 0 }}
              </div>
            </b-alert>
          </div>
        </div>
      </b-card-body>
      <b-table striped hover responsive show-empty class="mt-2 position-relative items-center" :per-page="perPage"
        :items="items" :fields="fields">

        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
    'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(total)="{ item }">
          {{ item.ref_total ? item.ref_total : 0 }}
        </template>
        <template #cell(total_dep)="{ item }">
          {{ item.dep_all }} = {{ item.dep_not_today }} + {{ item.dep_today }}
        </template>
        <template #cell(total_not_dep)="{ item }">
          {{ item.total_not_dep ? item.total_not_dep : 0 }}
        </template>
        <template #cell(total_dep2)="{ item }">
          {{ item.second_dep }} / {{ item.frist_dep }} ({{ isNaN(Number(item.frist_dep)) ||
    isNaN(Number(item.second_dep)) || Number(item.frist_dep) === 0 ? 0 : parseFloat(((Number(item.second_dep) *
      100) / Number(item.frist_dep)).toFixed(2)) }}%)
        </template>
        <template #cell(frist_dep_amount)="{ item }">
          {{ item.frist_dep_amount ? parseFloat(item.frist_dep_amount).toFixed(2) : 0.00.toFixed(2) }}
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group label="Per Page" label-cols="8" label-align="left" label-size="sm" label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1">
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions"
            @change="getData()" />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination v-model="page" :total-rows="totalRows" :per-page="perPage" first-number last-number
            prev-class="prev-item" next-class="next-item" class="mb-0" @input="getData()">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { BTr, BTd, BTfoot, BAlert, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';

export default {
  components: {
    BTr, BTd, BTfoot, BAlert, BFormGroup, BFormSelect, BPagination, BTable, BCard, BCardTitle, BCardBody, BOverlay, BIconController, BButton,
    flatPickr
  },
  data: () => ({
    perPage: 30,
    pageOptions: [30, 15, 20],
    totalRows: 1,
    page: 1,
    fields: [
      { key: 'username', label: 'ยูสเซอร์' },
      { key: 'fullname', label: 'ชื่อนามสกุล' },
      { key: 'total', label: 'ยอดผู้สมัคร' },
      { key: 'total_dep', label: 'จำนวนผู้ฝากเงิน(รวม = วันที่กรอง + ย้อนหลัง)' },
      { key: 'not_dep', label: 'จำนวนผู้ไม่ได้ฝากเงิน(เฉพาะวันที่กรอง)' },
      { key: 'total_dep2', label: 'จำนวนผู้ฝากสอง(%)' },
      { key: 'dep_condition', label: 'จำนวนผู้ฝากขั้นต่ำ 300' },
      { key: 'frist_dep_amount', label: 'จำนวนฝากแรก' },
    ],
    items: [],
    isLoading: false,
    totalData: null,
    dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
  }),
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/report/depandwit_kr', {
          params: {
            page: this.page, per_page: this.perPage, start_date: this.dateStart, end_date: this.dateEnd
          }
        })

        this.totalRows = data.total;
        this.totalData = data.total_data;
        this.items = data.data;
        console.log(data);

      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>